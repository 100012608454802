import HomePage from 'components/pages/HomePage';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { setCookie } from 'cookies-next';
import { getPartners } from 'services/partnersService';
import { getAmbassadors } from 'services/ambassadorsService';
import { getGeoInfo } from 'services/modalCountryService';

export default HomePage;

export const getServerSideProps = async props => {
  const req = props.req,
    res = props.res;

  if (
    !props.req.cookies.NEXT_LOCALE ||
    props.req.cookies.NEXT_LOCALE !== props.locale
  ) {
    setCookie('NEXT_LOCALE', props.locale, {
      req,
      res,
      maxAge: 60 * 60 * 24 * 365,
    });
  }

  const DEFAULT_GEO = props.locale === 'ru' ? 'RU' : 'US';

  const partnersItemsResponse = await getPartners({
    locale: props.locale,
    geo:
      props.req.cookies?.idchess_country_id ??
      (await getGeoInfo()) ??
      DEFAULT_GEO,
  });
  const ambassadorsItemsResponse = await getAmbassadors({
    locale: props.locale,
    geo:
      props.req.cookies?.idchess_country_id ??
      (await getGeoInfo()) ??
      DEFAULT_GEO,
  });
  return {
    props: {
      partnersData: partnersItemsResponse?.data?.partners,
      ambassadorsData: ambassadorsItemsResponse?.data?.ambassadors,
      ...(await serverSideTranslations(props.locale, [
        'common',
        'header',
        'footer',
        'homepage',
        'events',
        'partners',
        'media',
        'modal',
        'feedback',
        'modal',
        'howItWorks',
        'rewards',
        'reviews',
        'faq',
        'ambassadors',
        'products',
        'news',
        'blog',
      ])),
    },
  };
};
